import React, { useEffect, Fragment, useContext } from "react"
import { scrollToElement } from "../../../utility/utils"
import ImageTextRight from "../general/imageTextRight";
import ImageTextLeft from "../general/imageTextLeft";
import Image1 from "../../../../assets/img/send-money-canada.inline.svg"
import Image2 from "../../../../assets/img/prefered-currency-canada.inline.svg"
import Image3 from "../../../../assets/img/competitive-rates-canada.inline.svg"
import { StateContext } from "../../../../contextApi";
import WaitlistHeader from "./componenents/waitlistHeader";

const entryContent = {
  title: "Send money for free anytime.",
  subtitle: "Your Kuda account comes with 25 free transfers to other banks every month. That’s up to 15,000 naira saved on transfers every year.",

}
const firstSection = {
  title: "Make cross-border payments.",
  subtitle: "Whether you're supporting family or investing in opportunities, sending money from Canada is easy with the Kuda app.",
  buttonName: "Join the Waitlist",
  illustration: <Image1 />,
}

const secondSection = {
  title: "Send money at competitive rates.",
  subtitle: "Sending money to African countries shouldn’t cost you a lot. Use Kuda to make payments at rates you can afford.",
  buttonName: "Join the Waitlist",
  illustration: <Image2 />,
}
const thirdSection = {
  title: "Choose your preferred currency. ",
  subtitle: "For your maximum convenience, our multi-currency wallet gives you several currency options to make international money transfers.",
  buttonName: "Join the Waitlist",
  illustration: <Image3 />
}
const CanadaWaitlist = () => {
  const [{ }, dispatch] = useContext(StateContext)

  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])
  function openWaitlistForm() {
    dispatch({
      key: 'waitlistForm',
      value: true,
      type: "CHANGE_VIEW_MODALS",
    })
  }
  return (
    <Fragment>
      <WaitlistHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        onCallback={openWaitlistForm} 
      />
      <ImageTextLeft onCallback={openWaitlistForm} title={firstSection.title} subtitle={firstSection.subtitle} illustration={firstSection.illustration} name={firstSection.buttonName} url={firstSection.url} />
      <ImageTextRight onCallback={openWaitlistForm} title={secondSection.title} subtitle={secondSection.subtitle} illustration={secondSection.illustration} name={secondSection.buttonName} url={secondSection.url} />
      <ImageTextLeft onCallback={openWaitlistForm} title={thirdSection.title} subtitle={thirdSection.subtitle} illustration={thirdSection.illustration} name={thirdSection.buttonName} url={thirdSection.url} />

    </Fragment>
  )
}

export default CanadaWaitlist
