import React from "react";
import CAFlag from "../../../../../assets/img/flags/canada.png"
import GroupIllustration from "../../../../../assets/img/coming-soon-canada.svg"
import HeroImageBlur from "../../../../../assets/img/business/blur/canada-waitlist-blur.inline.svg"

import { Img } from "react-image";
import { WaitlistHeadingStyles } from "./canada-styles";
const WaitlistHeader = (props) => {
  
    return (
        <WaitlistHeadingStyles>
            <div className="kuda-entry--fold home-fold kuda-ignore--container" >
                <div className="kuda-entry--inner flex flex-column justify-between">
                    {/* Header text */}
                    <div className="flex flex-column  align-center items-center mx-auto px-3 justify-center bottom--spacing">
                        <h1 className="header-text-container d-inline-flex max-heading-width align-center careers-h1 text-center kuda-main--heading careers-text-center text-xlbold careers-mb-mobile color-primary title-bottom--spacing">
                            The money app for Africans in Canada. {' '}
                            <Img src={CAFlag} className="ca-flag ml-md-2 mx-auto mx-md-0" width={50} height={48}/>

                        </h1>

                        <p className="card-main--heading text-semi-bold careers-text-center careers-mb2-mobile color-black title-bottom--spacing">
                            Be the first to know when we launch.
                        </p>

                        <div className="kuda-view-jobs">
                            <a className="kuda-cta kuda-cta-long cta_button" onClick={()=> props.onCallback()}>
                                Let Me Know
                            </a>
                        </div>
                    </div>
                    <div className={`careers-iillustration-margin coming-soon-image-container`}>
                            <Img src={GroupIllustration}  className="coming-soon-image px-0" loader={<HeroImageBlur/>} loading="eager"/>
                        </div>
                </div>




                {/* <div className="kuda-entry--inner flex flex-column justify-between align-center" >
                    <div className="flex flex-column">
                        <div className="flex flex-row">
                            <h1 className="careers-h1 kuda-main--heading careers-text-center text-xlbold careers-mb-mobile color-primary title-bottom--spacing"> The money app for Africans in Canada. </h1>
                            <CAFlag />
                        </div>
                        <p className="card-main--heading text-semi-bold careers-text-center careers-mb2-mobile color-black title-bottom--spacing">
                            {props.subtitle}
                        </p>

                        <div className="kuda-view-jobs">
                            <Link to={"/careers/view-jobs/?location="} className="kuda-cta kuda-cta-long">
                                View all jobs
                            </Link>
                        </div>
                    </div>
                    <div className="career-header-padding">

                    </div>
                    <div className="kuda-section--50">
                        <div className={`kuda-illustration--center careers-iillustration-margin careers-mt-mobile`}>
                            <Img src={GroupIllustration} />
                        </div>
                    </div>
                </div> */}
            </div>
        </WaitlistHeadingStyles>

    )
}

export default WaitlistHeader;
