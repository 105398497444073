import styled from "styled-components";

export const WaitlistHeadingStyles = styled.div`
    padding-top: 50px;

    .kuda-cta{
        min-width: 156px;
    }
    @media (max-width: 991px){
        padding-top: 10px;
    }

    .max-heading-width{
        
        text-wrap: wrap;
    
    }
    .ca-flag{
        display: inline;
        @media (max-width: 670px){
            display: flex;
    justify-content: center;
    align-items: center;
        }
    }
    .coming-soon-image-container{
        display: flex;
        height:  100%;
        overflow:hidden;
        justify-content: center;
        @media (min-width: 991px){
            padding-top: 40px;
            align-items: flex-center;
        }
        
    }
    .coming-soon-image {
        width: 100%;
        object-position: center;
        @media (max-width: 991px){
            width: calc(100% + 80px);
        }
        @media (min-width: 1440px){

            width: calc(100% + 80px);
        }
    }
    .kuda-entry--inner:not(.kuda-cta--section){
      display: flex;
      justify-content: space-between
        @media (max-width: 991px){
             min-height: 0 !important;
            justify-content: space-between
        }
        @media (min-width: 991px){
            // max-height: calc(100vh - 120px);
            justify-content: space-between
        }
    }
`